@import "~bootstrap/dist/css/bootstrap.css";
@import '~font-awesome/css/font-awesome.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "/src/bootstrapStyle.css";
@import '@fortawesome/fontawesome-free/css/all.css';

body {
  /* background-image: url('assets/default_background.png'); */
  background-size: cover;
}

.table thead th,
td {
  text-transform: none;
  background-color: #2e3192;
  color: #fff;
}

.customer-btn {
  padding-bottom: 30px;
  padding-right: 30px;
}
